.heading {
  font-size: 30px;
  padding: 16px 0;
  text-align: center;
}

/*Loader*/
.search-loading {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
}

/*Show and hide*/
.show {
  display: inline-block;
}

.hide {
  display: none !important;
}

/*Prev and next*/

.nav-link-container {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}

.nav-link {
  color: #555;
  text-decoration: none;
  border: 1px solid #898989;
  padding: 10px 20px;
  margin-right: 10px;
}

.greyed-out {
  background: #f1f1f1;
  opacity: 0.5;
}

.form-control {
  margin-bottom: 10px;
}

.css-fade {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

#help-search-results {
  margin-bottom: 50px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
